//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         #2C3E50 !default;
$brand-success:         #A6C539 !default;
$brand-info:            #31AEBC !default;
$brand-warning:         #F39C12 !default;
$brand-danger:          #E74C3C !default;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color:            $gray-dark !default;

//** Global textual link color.
$link-color:            $brand-info !default;
$link-color:            darken($brand-info, 5%) !default;
$link-color:            saturate(lighten($brand-primary, 20%), 40%) !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          15px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.

// [converter] If $bootstrap-sass-asset-helper if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
$icon-font-path: "/fonts/" !default;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%) !default;
$navbar-inverse-bg:                         $brand-info !default;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color:                 #fff !default;
$navbar-inverse-link-hover-color:           #fff !default;
$navbar-inverse-link-hover-bg:              darken($navbar-inverse-bg, 5%) !default;
$navbar-inverse-link-active-color:          #fff !default;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 5%) !default;
$navbar-inverse-link-disabled-color:        #ccc !default;
$navbar-inverse-link-disabled-bg:           transparent !default;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-default-color:              $text-color !default;
$btn-default-bg:                 #fff !default;
$btn-default-border:             #dce4ec !default;

$btn-other-color:                #fff !default;
$btn-primary-color:              $btn-other-color;
$btn-success-color:              $btn-other-color;
$btn-info-color:                 $btn-other-color;
$btn-warning-color:              $btn-other-color;
$btn-danger-color:               $btn-other-color;


//== Forms
//
//##

$legend-border-color:            $gray-lighter !default;


//== Type
//
//##

//** Page header border color
$page-header-border-color:    $gray-lighter !default;
//** Horizontal line color.
$hr-border:                   $gray-lighter !default;
