@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/fonts/Lato-regular/Lato-regular.eot');
  src: url('/assets/fonts/Lato-regular/Lato-regular.eot?#iefix') format('embedded-opentype'),
       local('Lato Regular'),
       local('Lato-regular'),
       url('/assets/fonts/Lato-regular/Lato-regular.woff2') format('woff2'),
       url('/assets/fonts/Lato-regular/Lato-regular.woff') format('woff'),
       url('/assets/fonts/Lato-regular/Lato-regular.ttf') format('truetype'),
       url('/assets/fonts/Lato-regular/Lato-regular.svg#Lato') format('svg');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/Lato-700/Lato-700.eot');
  src: url('/assets/fonts/Lato-700/Lato-700.eot?#iefix') format('embedded-opentype'),
       local('Lato Bold'),
       local('Lato-700'),
       url('/assets/fonts/Lato-700/Lato-700.woff2') format('woff2'),
       url('/assets/fonts/Lato-700/Lato-700.woff') format('woff'),
       url('/assets/fonts/Lato-700/Lato-700.ttf') format('truetype'),
       url('/assets/fonts/Lato-700/Lato-700.svg#Lato') format('svg');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: italic;
  src: url('/assets/fonts/Lato-italic/Lato-italic.eot');
  src: url('/assets/fonts/Lato-italic/Lato-italic.eot?#iefix') format('embedded-opentype'),
       local('Lato Italic'),
       local('Lato-italic'),
       url('/assets/fonts/Lato-italic/Lato-italic.woff2') format('woff2'),
       url('/assets/fonts/Lato-italic/Lato-italic.woff') format('woff'),
       url('/assets/fonts/Lato-italic/Lato-italic.ttf') format('truetype'),
       url('/assets/fonts/Lato-italic/Lato-italic.svg#Lato') format('svg');
}

