@page {
    size: auto;
    margin: 10mm;
}

@media not screen {
    body, body>.container {
        margin: 0;
        padding: 0;
        font-size: 10pt;

    }

    .page-header {
        border-bottom: 2px solid black;

        h1 {
            margin-top: 0;
        }
    }

    h1 {
        font-size: 14pt;
    }

    footer {
        display: none;
    }
}

.page-header {
    margin-top: 0;
}

.btn-group {
    margin-bottom: 10px;
}

.panel-filter {
    background-color: $gray-lighter;
}

.panel-filter .form-inline .form-group, .btn-gap {
    margin-right: $panel-body-padding;
}

.table-middle > tbody > tr > td, .td-middle {
     vertical-align: middle !important;
}

.align-input {
    line-height: $input-height-base !important
}

.graph-forecast {
    height: 320px;
}

.graph-nominations {
    height: 550px;
}

.mb-1 {
      margin-bottom: floor(($font-size-base * $line-height-base) * .25) !important;
}

.mb-2 {
      margin-bottom: floor(($font-size-base * $line-height-base) * .5) !important;
}

abbr[title], acronym[title] {
    text-decoration-line: none;
}

@media (min-width: $screen-sm-min) {
    p.form-contract-static {
        margin-bottom: 0;
        padding-top: ($padding-base-vertical + 1);
    }
}

.mfa-logo:hover, .mfa-logo:focus {
    text-decoration: none;
}

date-input-polyfill {
    z-index: 2 !important;
}

.navbar-sign-in {
    margin-right: 0.25em;
}

.filter-group {
    display: inline-block;
}

.table-fax {
    thead>tr>th, tbody>tr>th, tfoot>tr>th, thead>tr>td, tbody>tr>td, tfoot>tr>td {
        border: 2px solid black !important;
    }

    .no-border {
        border: 0 !important;
    }
}

.table-fixed-col {
    position: relative;
    overflow: hidden;
    width: inherit;
    background-color: #ffffff;

    thead {
        position: relative;
        display: block;
        overflow: visible;
        width: inherit;

        th {
            min-width: 8em;
            background-color: #ffffff;
        }

        tr>th:first-child {
            position: relative;
            display: block;
        }
    }

    tbody {
        position: relative;
        display: block;
        overflow: scroll;
        width: inherit;
        height: calc(100vh - 26em);

        td {
            min-width: 8em;
            background-color: #ffffff;
        }

        tr>td:first-child {
            position: relative;
            display: block;
        }
    }
}

.table-condensed-xs {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 0 2pt;
        font-size: 10pt;
        line-height: 12pt;
      }
    }
  }
}

.table-compact {
  width: fit-content;
  margin: 0 auto;
}

.table-responsive {
  overflow: visible;
}

.min-width-expected {
    min-width: 80pt;
}

.min-width-reason {
    min-width: 128pt;
}

.pt-2_4em {
    padding-top: 2.4em;
}

.collapse-link {
    margin-left: 0.2em;
}

.collapse-link:before {
    font-family: 'Glyphicons Halflings';
    content: "\e113";
    float: left;
    transition: all 0.5s;
}

.collapse-link.collapsed:before {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}

.fixed-height {
    display: block;
    height: 22em;
    border: 1px solid #eee;
    padding: 1em;
    margin-bottom: 1em;
    overflow: scroll;
  }

@media print {
    .container {
      width: 100% !important;
    }
}

:target:before {
    content: "";
    display: block;
    height: 80px; /* fixed header height*/
    margin: -80px 0 0; /* negative fixed header height */
}

.align-middle {
    vertical-align: middle;
}

[editor] {
  cursor: pointer;
  &:after {
    font-family: 'Glyphicons Halflings';
    content: "\270f";
    float: right;
    margin-left: 1ch;
  }
}
