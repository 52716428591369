.dropdown-profile-img {
  vertical-align: text-bottom;
  padding-bottom: 2px;
  height: 17px;
  width: 17px;
}

.dropdown-text {
  display: block;
  padding: 3px 20px;
  line-height: $line-height-base;
  white-space: nowrap; // as with > li > a
}

 @media (max-width: $grid-float-breakpoint-max) {
  // Dropdowns get custom display when collapsed
  .open .dropdown-menu {
    .dropdown-header, .dropdown-text {
      padding: 5px 15px 5px 25px;
      color: $navbar-inverse-link-color;
    }
  }
}

.sidescroll-container {
  display: flex !important;
  flex-direction: row;
}

.sidescroll-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  white-space: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
}

.anchor {
  position: relative;
}

.cascade-bg {
  background: inherit;
}

@mixin nav-fade {
  width: 20px;
  @extend .cascade-bg;
  position: absolute;
  z-index: 1000;
  height: 100%;
}

div.nav-fade-in {
  @include nav-fade;
  left: calc(100% - 5px);
  mask: linear-gradient(90deg, #000 5px, #0000 100%);
}

div.nav-fade-out {
  @include nav-fade;
  right: calc(100% - 5px);
  mask: linear-gradient(90deg, #0000 0%, #000 calc(100% - 5px));
}

.dropdown {
  position: static !important;
}

.info-tooltip {
  cursor: default;
  float: right;
  position: relative;
  * {
    visibility: hidden;
  }
  &:hover .info-tooltip-text {
    visibility: visible;
  }
  .info-tooltip-text {
    width: max-content;
    min-width: 10vw;
    max-width: 15vw;
    background-color: rgb(40, 40, 40);
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 100;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent rgb(40, 40, 40) transparent;
    }
  }
}
