.markdown {
    ol, ul {
        padding-left: 0;
    }

    ol {
        counter-reset: section;
    }

    ol > li {
        counter-increment: section;
        display: block
    }

    ol > li:before {
        content: counters(section, ".") ". ";
        font-weight: bold;
    }

    ol > li > p {
        margin-left: 40px;
    }

    ol > li > p:first-child {
        margin-top: -1.42857em;
    }

    ol ol > li {
        display: block;
    }

    ol ol > li:before {
        content: counters(section, ".") ". ";
        font-weight: bold;
    }

    ul {
        list-style: lower-alpha;
        counter-reset: clause;
    }

    ul > li {
        counter-increment: clause;
        display: block;
    }

    ul > li:before {
        content: "(" counter(clause, lower-alpha) ")";
        font-weight: bold;
        margin-left: 40px;
    }

    ul > li > p {
        margin-left: 80px;
    }

    ul > li > p:first-child {
        margin-top: -1.42857em;
    }
}